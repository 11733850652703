import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { styled, Grid, Col, Row } from '@smooth-ui/core-sc';
import RexelLogo from './images/login-logo-rexel-@2x.jpg';
import JRTLogo from './images/login-logo-john_r_turk-@2x.jpg';
import IdealLogo from './images/login-logo-ideal-@2x.jpg';
import LSLogo from './images/login-logo-lear-@2x.jpg';
import { withSettings } from 'containers/WebApp/SettingsContext';

const BannerWrapper = styled.div`
  background-color: #fff;
`;

const BannerContainer = styled.div`
  .align__logo {
    text-align: center;
  }

  img {
    width: 240px;
  }
`;

const RexelBanners = props => (
  <BannerWrapper>
    <BannerContainer>
      <Grid>
        <Row justifyContent="center">
          <Col xs={12} md={props.login ? 12 : 8}>
            <Grid>
              <Row justifyContent="center">
                <Col xs={6} sm={3} className="align__logo">
                  <img alt="Rexel" src={RexelLogo} />
                </Col>
                <Col xs={6} sm={3} className="align__logo">
                  <img alt="Ideal" src={IdealLogo} />
                </Col>
                <Col xs={6} sm={3} className="align__logo">
                  <img alt="Lear &amp; Smith" src={LSLogo} />
                </Col>
                <Col xs={6} sm={3} className="align__logo">
                  <img alt="John R. Turk" src={JRTLogo} />
                </Col>
              </Row>
            </Grid>
          </Col>
        </Row>
      </Grid>
    </BannerContainer>
  </BannerWrapper>
);

RexelBanners.propTypes = {
  login: PropTypes.bool.isRequired,
};

export default withSettings(RexelBanners);
