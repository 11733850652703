import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Style and SEO
import { Col, Grid, Row, styled, up, css, th, thd } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';
import IconWrapper from '_platform/src/components/SvgIcon/IconWrapper';
import SvgIcon from '_platform/src/components/SvgIcon/SvgIcon';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import LoadAsync from '_platform/src/utils/LoadAsync';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';
import RexelBanners from '../../components/CustomComponents/Banners/RexelBanners';

import giftcardMobile1x from './images/home-rewards-banner-mobile-@1x.jpg';
import giftcardMobile2x from './images/home-rewards-banner-mobile-@2x.jpg';
import giftcardTablet1x from './images/home-rewards-banner-tablet-@1x.jpg';
import giftcardTablet2x from './images/home-rewards-banner-tablet-@2x.jpg';
import giftcardDesktop1x from './images/home-rewards-banner-@1x.jpg';
import giftcardDesktop2x from './images/home-rewards-banner-@2x.jpg';

import Clipsal from '../../components/CustomComponents/CustomFooter/images/ClipsalLogo@2x.png';
import Evolt from '../../components/CustomComponents/CustomFooter/images/evolt-atom-air-ektor@2x.png';
import Trader from '../../components/CustomComponents/CustomFooter/images/Traderlogo@2x.png';

import bannerMobile1x from '../../components/CustomComponents/Banners/images/BlankBanner-320x280-header-banner-mobile-@1x.jpg';
import bannerTablet1x from '../../components/CustomComponents/Banners/images/BlankBanner-1024x280-header-banner-tablet-@1x.jpg';
import bannerDesktop1x from '../../components/CustomComponents/Banners/images/Blank-header-header-banner-@1x.jpg';
import bannerMobile2x from '../../components/CustomComponents/Banners/images/BlankBanner-640x560-header-banner-mobile-@2x.jpg';
import bannerTablet2x from '../../components/CustomComponents/Banners/images/BlankBanner-2048x560-header-banner-tablet-@2x.jpg';
import bannerDesktop2x from '../../components/CustomComponents/Banners/images/Blank-header-banner-@2x.jpg';

import Icon1 from './images/IncreasedRewardsIcon@2x.png';
import Icon2 from './images/ExpandSupNetworkIcon@2x.png';
import Icon3 from './images/ExtProgramDurationIcon@2x.png';
import Icon4 from './images/FtSupplierIcon@2x.png';

import AdvantageLogo from 'theme/login-main_logo1-@2x.png';

const StatementProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementProvider" */ '../StatementProvider/StatementProvider'
  )
);

const CustomStatementSummary = LoadAsync(() =>
  import(
    /* webpackChunkName: "customStatementSummary" */ '../StatementPage/CustomStatementSummary'
  )
);

const RewardsProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "rewardsProvider" */ '../RewardsProvider/RewardsProvider'
  )
);

const PageContainer = styled.div`
  .banner__title {
    letter-spacing: 0;
  }
`;

const StyledLogo = styled.img`
  height: 122px;
  ${up(
    'md',
    css`
      height: 173px;
    `
  )};
`;

const FeaturedSupplierContainer = styled.div`
  color: ${th('primary')};

  .divider {
    padding-bottom: 40px;
    border-bottom: 1px solid #000;
  }
`;

const IntroContainer = styled.div`
  align-items: center;
  background-color: ${th('secondary')};
  background-image: url(${bannerMobile1x});
  background-position: center top;
  background-size: contain;
  color: #fff;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bannerMobile2x});
  }

  ${up(
    'sm',
    css`
      background-image: url(${bannerTablet1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bannerTablet2x});
      }
    `
  )}
  ${up(
    'lg',
    css`
      background-image: url(${bannerDesktop1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bannerDesktop2x});
      }
    `
  )}
`;

const GiftCardContainer = styled.div`
  align-items: center;
  background-color: ${th('primary')};
  background-image: url(${giftcardMobile1x});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 80px 0;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${giftcardMobile2x});
  }

  ${up(
    'sm',
    css`
      background-image: url(${giftcardTablet1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${giftcardTablet2x});
      }
    `
  )}
  ${up(
    'lg',
    css`
      background-image: url(${giftcardDesktop1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${giftcardDesktop2x});
      }
    `
  )}

.highlight {
    padding-bottom: 40px;
    font-size: 24px;
    font-weight: bold;
  }

  .text--primary {
    color: ${th('primary')};
  }
`;

const FeatureContainer = styled.div`
  border: 3px solid #fff;
  height: 275px;
  margin: 10px;

  h4 {
    background-color: #fff;
    color: ${thd('secondary', '#000')};
    padding: 0;
    margin: 0;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
    font-variation-settings: 'wght' 400;

    .img-container {
      width: 75px;
      margin-right: 0.25rem;
      padding-left: 5px;

      img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .heading {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 250px;
    }
  }

  p {
    padding: 10px;
  }
`;

const DarkBlueSection = styled.section`
  align-items: center;
  background-color: #c60821;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  width: 100%;

  .spacer--around--small {
    padding-right: 0;
    ${up(
      'md',
      css`
        padding-right: 20px;
      `
    )};

    ${up(
      'lg',
      css`
        padding-right: 20px;
      `
    )};
  }

  .how--it--works {
    border-top: 1px solid #fff;
  }

  .promo--dates {
    margin: 40px 0;
    padding: 40px 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-size: 24px;
    font-weight: bold;
  }

  .text--primary {
    color: ${th('primary')};
  }
`;

const StatementStyles = styled.div`
  padding-bottom: 30px;
  //border-bottom: 1px solid #fff;

  section:not(:last-of-type) {
    padding-bottom: 1.25rem;
  }
  .points-value span {
    color: #fff;
  }
  h2 {
    text-align: center;
  }
  .statement-summary {
    min-height: 450px;

    ${up(
      'md',
      css`
        min-height: 300px;
      `
    )};
  }

  span.points-value span {
    color: #000;
  }

  .statement-summary-label {
    span.highlight-primary {
      color: #fff;
    }
  }

  .statement-summary-plus {
    margin: 30px 0;

    ${up(
      'md',
      css`
        margin-top: 220px;
      `
    )};
  }

  .statement-entries-plus {
    margin: 30px 0;

    ${up(
      'md',
      css`
        margin-top: 90px;
      `
    )};
  }

  .fine-print {
    color: ${th('primary')};
    font-weight: bold;
    margin-top: 25px;
  }

  .statement-dollars-label {
    font-size: 30px;
    &:before {
      content: '$';
    }
  }

  .statement-dollars-redeemed-label {
    font-size: 30px;
    &:before {
      content: '($';
    }
    &:after {
      content: ')';
    }
  }

  .statement-summary-data div {
    padding: 0 15px;
  }
  p.button--wrapper {
    margin-top: 75px;
    text-align: center;
    a {
      text-decoration: none;
      color: #fff;
      font-weight: bold;
      background-color: ${th('primary')};
      padding: 10px 50px 10px 50px;
    }
  }
`;

const HomePage = ({ settings }) => {
  return (
    <PageContainer>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <RexelBanners login={false} />
        <Grid>
          <Row justifyContent="center">
            <Col xs={12} className="text-center spacer--around">
              <StyledLogo src={AdvantageLogo} alt="Rexel Online Advantage" />
            </Col>
          </Row>
        </Grid>
        <IntroContainer>
          <Grid>
            <Row justifyContent="center">
              <Col xs={12} className="text-center spacer--around">
                <h1>Welcome to Rexel Online Advantage</h1>
                <p>
                  Back in 2024 with great new features and suppliers & more gift
                  cards
                </p>
                <h2>What's New?</h2>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col xs={12} md={4} className="text-center">
                <FeatureContainer>
                  <h4>
                    <div className="img-container">
                      <img
                        src={Icon1}
                        alt="Increased Rewards"
                        height={40}
                        width={62}
                      />
                    </div>
                    <div className="heading">Increased Rewards</div>
                  </h4>
                  <p>
                    We've listened to your feedback, and we're excited to share
                    that we've upped the ante! Now, you can enjoy up to 6 e-gift
                    cards per month as a reward for your loyalty. It's our way
                    of saying thank you for being a valued customer.
                  </p>
                </FeatureContainer>
              </Col>
              <Col xs={12} md={4} className="text-center">
                <FeatureContainer>
                  <h4>
                    <div className="img-container">
                      <img
                        src={Icon2}
                        alt="Expanded Supplier Network"
                        height={40}
                      />
                    </div>
                    <div className="heading">Expanded Supplier Network</div>
                  </h4>
                  <p>
                    We're broadening our horizons by partnering with three
                    fantastic suppliers, offering you even more opportunities to
                    earn rewards.
                  </p>
                </FeatureContainer>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer--bottom">
              <Col xs={12} md={4} className="text-center">
                <FeatureContainer>
                  <h4>
                    <div className="img-container">
                      <img
                        src={Icon3}
                        alt="Expanded Program Duration"
                        height={60}
                      />
                    </div>
                    <div className="heading">Extended Program Duration</div>
                  </h4>
                  <p>
                    Get ready for a loyalty journey that lasts from March to
                    November. More time means more chances to earn, redeem, and
                    enjoy the benefits.
                  </p>
                </FeatureContainer>
              </Col>
              <Col xs={12} md={4} className="text-center">
                <FeatureContainer>
                  <h4>
                    <div className="img-container">
                      <img
                        src={Icon4}
                        alt="Featured Supplier of the Month"
                        height={40}
                      />
                    </div>
                    <div className="heading">
                      Featured Supplier
                      <br />
                      of the Month
                    </div>
                  </h4>
                  <p>
                    Each month, we'll shine a spotlight on one of our
                    participating suppliers. It's a fantastic opportunity to
                    discover new products and earn extra rewards.
                  </p>
                </FeatureContainer>
              </Col>
            </Row>
          </Grid>
        </IntroContainer>

        <DarkBlueSection>
          <StatementStyles>
            <StatementProvider
              fetch={[
                {
                  endpoint: '/Statement/v1/Rexel',
                  section: 'loyalty',
                },
              ]}
              render={({ statement }) =>
                !statement || !statement.data ? (
                  <LoadingAnimation /> // Loader instead of `null`?
                ) : (
                  <CustomStatementSummary
                    fill="#000"
                    statement={statement.data.loyalty}
                  />
                )
              }
            />
            <p className="button--wrapper">
              <Link to="/my-activity">Find out more</Link>
            </p>
          </StatementStyles>

          <Grid className="how--it--works">
            <Row justifyContent="center">
              <Col xs={10} className="text-center">
                <h2>How It Works</h2>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col xs={10} md={4} className="text-center spacer--around--small">
                <IconWrapper>
                  <SvgIcon
                    icon="icon_giftcard_50"
                    scaleWidth="64"
                    scaleHeight="42"
                    width="100px"
                    height="65px"
                    fill="#000"
                    title="Additional $50 Gift Card"
                  />
                </IconWrapper>
                <div>
                  Spend $500 + gst on one order online on nominated products
                  from the featured vendor to qualify for a $50 gift card.
                </div>
              </Col>
              <Col xs={10} md={4} className="text-center spacer--around--small">
                <IconWrapper>
                  <SvgIcon
                    icon="icon-giftcard-6"
                    scaleWidth="64"
                    scaleHeight="42"
                    width="100px"
                    height="65px"
                    fill="#000"
                    title="6 Gift Cards"
                  />
                </IconWrapper>
                <div>
                  Customers receive a e-gift card for the first 6 qualifying
                  ONLINE orders each promotional month only
                </div>
              </Col>
            </Row>
          </Grid>
          <Grid className="promo--dates">
            <Row justifyContent="center">
              <Col xs={10} className="text-center">
                Promotion Starts:{' '}
                <span className="text--primary">1st March 2024</span>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col xs={10} className="text-center">
                Promotion Ends:{' '}
                <span className="text--primary">30th November 2024</span>
              </Col>
            </Row>
          </Grid>
        </DarkBlueSection>
        <FeaturedSupplierContainer>
          <Grid>
            <Row justifyContent="center">
              <Col xs={12} className="text-center">
                <h2 className="text--secondary">Featured Vendors</h2>
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col xs={6} className="text-center divider">
                <img src={Clipsal} alt="Clipsal" />
                <h3 className="text--secondary">
                  March, June, October, November
                </h3>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer--top">
              <Col xs={6} className="text-center divider">
                <img src={Evolt} alt="evolt" />
                <h3 className="text--secondary">April, September</h3>
              </Col>
            </Row>
            <Row justifyContent="center" className="spacer">
              <Col xs={6} className="text-center">
                <img src={Trader} alt="Trader" />
                <h3 className="text--secondary">May, August</h3>
              </Col>
            </Row>
          </Grid>
        </FeaturedSupplierContainer>
        <GiftCardContainer>
          <Grid>
            <Row justifyContent="center">
              <Col xs={10} md={8} className="highlight text--center">
                Use your gift card dollars to select from our range of over
                <br />
                <span className="text--primary">
                  50 digital e-gift cards
                </span>{' '}
                from leading brands
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col xs={10} md={8} className="text--center">
                <p>
                  <strong>
                    You can claim each week when you receive your tracker email
                    or build up your dollars and claim multiple cards. Gift card
                    dollars can be claimed until 31st December 2024
                  </strong>
                </p>
              </Col>
            </Row>
          </Grid>
        </GiftCardContainer>
        <RewardsProvider
          showFeatured
          rewardLabels={{ highlightHeading: 'Rewards of the Month' }}
        />
      </PrivateComponent>
    </PageContainer>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
